$(document).ready(function(){


    $('.start-btn').click(function () {
        $('.message-btn').toggleClass('active');
    });

    $(".lang_block").click(function(e) {
        $(this).toggleClass("open");
        e.stopPropagation();
    });
    $("body").click(function() {
        $(".lang_block").removeClass('open');
    });

    if ($(window).scrollTop()>60) {
        $(".header_scroll_wrapp").show();
        $(".header_top_wrapp").hide();
    } else {
        $(".header_top_wrapp").show();
        $(".header_scroll_wrapp").hide();
    }
    $(".hamburger").click(function(e) {
        $('.scrolled_menu').toggleClass("active");
        $(".full_menu_wrapp").toggleClass('open');
        $('body').toggleClass("over_h");
    });


    $('.popup-with-move-anim').magnificPopup({
        type: 'inline',

        fixedContentPos: false,
        fixedBgPos: true,

        overflowY: 'auto',
        fixedContentPos: 'true',
        closeBtnInside: true,
        preloader: false,

        midClick: true,
        removalDelay: 300,
        mainClass: 'my-mfp-slide-bottom'
    });

    $('.mfp-close').click(function () {
        $.magnificPopup.close();
    })

});

$(document).on("scroll", window, function () {
    if ($(window).scrollTop()>60) {
        $(".header_scroll_wrapp").show();
        $(".header_top_wrapp").hide();
    } else {
        $(".header_top_wrapp").show();
        $(".header_scroll_wrapp").hide();
    }
});



$(document).ready(function() {
    $('.popup-youtube, .popup-vimeo, .popup-gmaps').magnificPopup({
        disableOn: 700,
        type: 'iframe',
        mainClass: 'mfp-fade',
        removalDelay: 160,
        preloader: false,
        fixedContentPos: false
    });
});


$('.multiple-items').slick({
    infinite: true,
    slidesToShow: 1,
    nav: true,
    slidesToScroll: 1,
    centerMode: true,
    appendArrows: '#portfolioNav',
    centerPadding: '25%',
    nextArrow: '<button class="slick-arrow  slick-next"><img src="images/right-arrow.svg" alt=""></button> ',
    prevArrow: '<button class="slick-arrow  slick-prev"><img src="images/left-arrow.svg" alt=""></button>',
});
var allSl = $('.multiple-items .slide-item').length;
$('.all-slide').text(allSl);
$('.multiple-items').on('afterChange', function(event, slick, currentSlide, nextSlide){
    $('.current-slide').text(currentSlide + 1);
});


$(document).ready(function() {
    $('.popup-gallery').magnificPopup({
        delegate: 'a',
        type: 'image',
        tLoading: 'Loading image #%curr%...',
        mainClass: 'mfp-img-mobile',
        gallery: {
            enabled: true,
            navigateByImgClick: true,
            preload: [0,1] // Will preload 0 - before current, and 1 after the current image
        },
        image: {
        }
    });
});

$('.filter-portf').click(function (e) {
    e.preventDefault();
    $('.filter-portfolio_container').toggleClass('active');
})

$('.filter-portfolio_container .close').click(function () {
    $('.filter-portfolio_container').toggleClass('active');

})